<template>
  <div>
    <b-overlay :show="show" no-wrap fixed z-index="9999">
      <template v-slot:overlay>
        <div class="d-flex align-items-center">
          <b-spinner small type="grow" variant="dark"></b-spinner>
          <b-spinner type="grow" variant="dark"></b-spinner>
          <b-spinner small type="grow" variant="dark"></b-spinner>
        </div>
      </template>
    </b-overlay>
    <b-button variant="success" v-b-modal.new-modal>ثبت بنر</b-button>
    <b-card title="بنرها" class="mt-3">
      <b-button
        v-b-toggle.filterBox
        variant="primary"
        class="mb-3 position-absolute"
        style="top:20px; left: 20px;"
        size="sm"
        ><i class="fa fa-bars"></i
      ></b-button>
      <b-collapse id="filterBox" class="my-2">
        <b-card>
          <form @submit.prevent="searchItems()" id="search-item">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="title">عنوان</label>
                  <b-form-input
                    id="title"
                    v-model="searchParams.title"
                  ></b-form-input>
                </div>
              </div>

              <div class="col-md-6">
                <b-form-group label="وضعیت" label-for="status">
                  <b-form-select id="status" v-model="searchParams.status">
                    <b-form-select-option value="1">فعال</b-form-select-option>
                    <b-form-select-option value="0"
                      >غیرفعال</b-form-select-option
                    >
                    <b-form-select-option value="">همه</b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </div>
            </div>
            <button
              class="btn btn-primary btn-sm float-left"
              type="submit"
              :disabled="disabled"
            >
              جست و جو
            </button>
          </form>
        </b-card>
      </b-collapse>
      <b-table
        responsive
        striped
        hover
        v-if="items.data"
        :fields="table_fields"
        :items="items.data.data"
      >
        <template v-slot:cell(created_at)="data">
          {{ data.item.created_at | persianDate }}
        </template>

        <template v-slot:cell(start_date)="data">
          <span v-if="data.item.start_date">
            {{ data.item.start_date | persianDate }}</span
          >
        </template>

        <template v-slot:cell(end_date)="data">
          <span v-if="data.item.end_date">
            {{ data.item.end_date | persianDate }}</span
          >
        </template>

        <template v-slot:cell(image)="data">
          <a :href="data.item.image" target="_blank">
            <img
              :src="data.item.image"
              class="thumb-sm img-thumbnail"
              style="width: 50px"
            />
          </a>
        </template>

        <template v-slot:cell(status)="data">
          <span class="badge badge-success" v-if="data.item.status == 1"
            >فعال</span
          >
          <span class="badge badge-light" v-else>غیرفعال</span>
        </template>

        <template v-slot:cell(edit)="data">
          <button class="btn btn-primary" @click="editItem(data.index)">
            <i class="fa fa-edit"></i>
          </button>
        </template>
        <template v-slot:cell(delete)="data">
          <button class="btn btn-danger" @click="deleteItem(data.item.id)">
            <i class="fa fa-close"></i>
          </button>
        </template>
      </b-table>
      <div
        class="alert alert-danger text-right"
        v-if="items.data && items.data.data == ''"
      >
        <span class="text-right">موردی یافت نشد ! </span>
      </div>
    </b-card>

    <pagination
      :limit="2"
      v-if="items.data && !search"
      :data="items.data"
      @pagination-change-page="loadItems"
    >
    </pagination>
    <pagination
      :limit="2"
      v-else-if="items.data && search"
      :data="items.data"
      @pagination-change-page="searchItems"
    >
    </pagination>

    <b-modal id="new-modal" title="ثبت بنر" size="lg" hide-footer>
      <div>
        <form @submit.prevent="newItem" id="new-item">
          <div class="row">
            <div class="col-md-6">
              <b-form-group>
                <label for="title">
                  عنوان
                  <i
                    class="fas fa-asterisk text-danger"
                    style="font-size: 0.5rem;"
                  ></i
                ></label>
                <b-form-input
                  id="title"
                  name="title"
                  data-required
                  :disabled="disabled"
                ></b-form-input>
              </b-form-group>

              <b-form-group>
                <label for="description">
                  توضیحات
                  <i
                    class="fas fa-asterisk text-danger"
                    style="font-size: 0.5rem;"
                  ></i
                ></label>
                <b-form-textarea
                  id="description"
                  name="description"
                  data-required
                  :disabled="disabled"
                ></b-form-textarea>
              </b-form-group>

              <b-form-group>
                <label for="position">
                  موقعیت
                  <i
                    class="fas fa-asterisk text-danger"
                    style="font-size: 0.5rem;"
                  ></i
                ></label>
                <!-- <b-form-input id="position" name="position"  value="a1"></b-form-input> -->
                <b-form-select
                  name="position"
                  id="position"
                  v-model="position_selected"
                  :disabled="disabled"
                >
                  <b-form-select-option value="a1">a1</b-form-select-option>
                  <b-form-select-option value="a2">a2</b-form-select-option>
                  <b-form-select-option value="a3">a3</b-form-select-option>
                  <b-form-select-option value="a3-mobile"
                    >a3 mobile</b-form-select-option
                  >
                  <b-form-select-option value="a2-mobile"
                    >a2 mobile</b-form-select-option
                  >
                  <b-form-select-option value="a4-mobile"
                    >a4 mobile</b-form-select-option
                  >
                  <b-form-select-option value="a5-mobile"
                    >a5 mobile</b-form-select-option
                  >
                </b-form-select>
              </b-form-group>
              <b-form-group label="لینک" label-for="link">
                <b-form-input
                  id="link"
                  name="link"
                  :disabled="disabled"
                ></b-form-input>
              </b-form-group>
            </div>

            <div class="col-md-6">
              <b-form-group label="تاریخ شروع" label-for="start_date">
                <date-picker
                  type="datetime"
                  name="start_date"
                  format="jYYYY-jMM-jDD HH:mm"
                />
              </b-form-group>
              <b-form-group label="تاریخ پایان" label-for="end_date">
                <date-picker
                  type="datetime"
                  name="end_date"
                  format="jYYYY-jMM-jDD HH:mm"
                />
              </b-form-group>

              <b-form-group>
                <label for="image">
                  تصویر
                  <i
                    class="fas fa-asterisk text-danger"
                    style="font-size: 0.5rem;"
                  ></i
                ></label>
                <b-form-file
                  id="image"
                  name="image"
                  :disabled="disabled"
                ></b-form-file>
              </b-form-group>
              <b-form-group>
                <label for="image_alt">
                  alt تصویر
                  <i
                    class="fas fa-asterisk text-danger"
                    style="font-size: 0.5rem;"
                  ></i
                ></label>
                <b-form-input
                  id="image_alt"
                  name="image_alt"
                  data-required
                  :disabled="disabled"
                ></b-form-input>
              </b-form-group>
              <div class="form-group">
                <label class="custom-switch" style="margin-top: 40px;">
                  <input
                    type="checkbox"
                    name="status"
                    class="custom-switch-input"
                    v-model="selected_status"
                    :disabled="disabled"
                  />
                  <span class="custom-switch-indicator"></span>
                  <span class="custom-switch-description">وضعیت</span>
                </label>
              </div>
            </div>
          </div>

          <div class="clearfix">
            <button
              class="btn btn-success float-right"
              type="submit"
              :disabled="disabled"
            >
              ثبت
            </button>
            <b-button
              class="float-left"
              variant="white"
              type="button"
              @click="$root.$emit('bv::hide::modal', 'new-modal')"
              >بستن</b-button
            >
          </div>
        </form>
      </div>
    </b-modal>

    <b-modal id="edit-modal" title="ویرایش بنر" size="lg" hide-footer>
      <div v-if="edit.id">
        <form @submit.prevent="itemEdited" id="edit-item">
          <div class="row">
            <div class="col-md-6">
              <b-form-group>
                <label for="title">
                  عنوان
                  <i
                    class="fas fa-asterisk text-danger"
                    style="font-size: 0.5rem;"
                  ></i
                ></label>
                <b-form-input
                  id="title"
                  name="title"
                  v-model="edit.title"
                  :disabled="disabled"
                ></b-form-input>
              </b-form-group>

              <b-form-group>
                <label for="description">
                  توضیحات
                  <i
                    class="fas fa-asterisk text-danger"
                    style="font-size: 0.5rem;"
                  ></i
                ></label>
                <b-form-textarea
                  id="description"
                  name="description"
                  v-model="edit.description"
                  :disabled="disabled"
                ></b-form-textarea>
              </b-form-group>

              <b-form-group>
                <label for="position">
                  موقعیت
                  <i
                    class="fas fa-asterisk text-danger"
                    style="font-size: 0.5rem;"
                  ></i
                ></label>
                <!-- <b-form-input id="position" name="position"  value="a1"></b-form-input> -->
                <b-form-select
                  name="position"
                  id="position"
                  v-model="edit.position"
                  :disabled="disabled"
                >
                  <b-form-select-option value="a1">a1</b-form-select-option>
                  <b-form-select-option value="a2">a2</b-form-select-option>
                  <b-form-select-option value="a3">a3</b-form-select-option>
                  <b-form-select-option value="a3-mobile"
                    >a3 mobile</b-form-select-option
                  >
                  <b-form-select-option value="a2-mobile"
                    >a2 mobile</b-form-select-option
                  >
                  <b-form-select-option value="a4-mobile"
                    >a4 mobile</b-form-select-option
                  >
                  <b-form-select-option value="a5-mobile"
                    >a5 mobile</b-form-select-option
                  >
                </b-form-select>
              </b-form-group>
              <b-form-group label="لینک" label-for="link">
                <b-form-input
                  id="link"
                  name="link"
                  v-model="edit.link"
                  :disabled="disabled"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="تاریخ شروع" label-for="start_date">
                <date-picker
                  v-if="edit.start_date == null"
                  type="datetime"
                  format="jYYYY-jMM-jDD HH:mm"
                  name="start_date"
                />
                <date-picker
                  v-else
                  v-model="editStartDate"
                  type="datetime"
                  format="jYYYY-jMM-jDD HH:mm"
                  name="start_date"
                />
              </b-form-group>
              <b-form-group label="تاریخ پایان" label-for="end_date">
                <date-picker
                  v-if="edit.end_date == null"
                  type="datetime"
                  format="jYYYY-jMM-jDD HH:mm"
                  name="end_date"
                />
                <date-picker
                  v-else
                  v-model="editEndDate"
                  type="datetime"
                  format="jYYYY-jMM-jDD HH:mm"
                  name="end_date"
                />
              </b-form-group>

              <b-form-group>
                <label for="image">
                  تصویر
                  <i
                    class="fas fa-asterisk text-danger"
                    style="font-size: 0.5rem;"
                  ></i
                ></label>
                <b-form-file
                  id="image"
                  name="image"
                  :disabled="disabled"
                ></b-form-file>
              </b-form-group>
              <b-form-group>
                <label for="title">
                  alt تصویر
                  <i
                    class="fas fa-asterisk text-danger"
                    style="font-size: 0.5rem;"
                  ></i
                ></label>
                <b-form-input
                  id="image_alt"
                  name="image_alt"
                  v-model="edit.image_alt"
                  :disabled="disabled"
                ></b-form-input>
              </b-form-group>
              <div class="form-group ">
                <label class="custom-switch" style="margin-top: 40px;">
                  <input
                    type="checkbox"
                    name="status"
                    class="custom-switch-input"
                    v-model="edit.status"
                    :disabled="disabled"
                  />
                  <span class="custom-switch-indicator"></span>
                  <span class="custom-switch-description">وضعیت</span>
                </label>
              </div>
            </div>
          </div>
          <div class="clearfix">
            <button
              class="btn btn-primary float-right"
              type="submit"
              :disabled="disabled"
            >
              ویرایش
            </button>
            <b-button
              class="float-left"
              variant="white"
              type="button"
              @click="$root.$emit('bv::hide::modal', 'edit-modal')"
              >بستن</b-button
            >
          </div>
          <input type="hidden" value="put" name="_method" />
        </form>
      </div>
    </b-modal>
  </div>
</template>
<script>
import mixins from "./mixins/mixins";

export default {
  mixins: [mixins],
  data() {
    return {
      url: "/api/admin/banner",
      title: "بنرها",
      banner: [],
      table_fields: [
        { key: "id", label: "شناسه " },
        { key: "title", label: "عنوان" },
        { key: "image", label: "تصویر" },
        { key: "position", label: "موقعیت" },
        { key: "status", label: "وضعیت" },
        { key: "start_date", label: "تاریخ شروع" },
        { key: "end_date", label: "تاریخ پایان" },
        { key: "edit", label: "ویرایش " },
        { key: "delete", label: "حذف" },
      ],
      selected_status: "1",
      selected_filter_status: "",
      position_selected: "a1",
    };
  },
  computed: {
    editStartDate() {
      return window.moment(this.edit.start_date).format("jYYYY-jMM-jDD HH:MM");
    },
    editEndDate() {
      return window.moment(this.edit.end_date).format("jYYYY-jMM-jDD HH:MM");
    },
  },
  methods: {
    loadBanner() {
      this.$axios.get(this.$root.baseUrl + this.url).then((response) => {
        this.banner = response.data.data.data;
      });
    },
    editItem(index) {
      this.edit = {};
      let item = window.clone(this.items.data.data[index]);
      item.index = index;
      this.edit = item;
      this.$root.$emit("bv::show::modal", "edit-modal");
    },
  },
  created() {
    // this.loadBanner()
    this.loadItems();
    this.searchParams = {
      title: "",
      status: "",
    };
  },
};
</script>
